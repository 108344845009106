import React, {useState} from 'react';
import './ref.css'
import ref from './ref.json'
import BottomTop from "../../elements/scroll/BottomTop";


const Referanser = () => {
    const [chapters, setChapters] = useState(ref.chapters)
    return (
        <div>
            <BottomTop/>
            <div className="container">
                <div className="head">
                    <img src="Tenker.png" alt=""/>
                </div>
                <div className="title">
                    <h1 className="title_main">Inkunabulasjon</h1>
                    <h3 className="title_sub">Digitale referanser</h3>
                </div>
            </div>


            {chapters.map((chapter) => (
                <Chapter key={chapter.id} chapter={chapter}/>
            ))}
        </div>
    );
};

export default Referanser;

const Chapter = ({chapter}) => {
    const [open, setOpen] = useState(false)

    function clicked() {
        setOpen(!open)
    }

    return (
        <div key={"chapter__" + chapter.id} className="chapter" onClick={clicked}>
            <h2 key={"chapter__head__" + chapter.id} className={"title_chap "}>{chapter.title}</h2>
            <div key={"chapter__body__" + chapter.id} className={"references " + (open?(""):("hidden"))} id={"chapter__" + chapter.id}>
                {
                    chapter.references.map((reference, index) => (
                        <Reference key={chapter.id + " " + index} reference={reference} index={index} chapterId={chapter.id}/>
                    ))
                }
            </div>
        </div>
    );
};


const Reference = ({reference, index, chapterId}) => {
    return (
        <div key={"Ref__K" + chapterId + "__I" + index} className="reference"><a key={"Ref__K" + chapterId + "__I" + index + "__a"} href={reference.url} target="_blank"><h3 key={"Ref__K" + chapterId + "__I" + index + "__h3"}>{reference.title}</h3></a></div>
    );
};
